import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ExtraExtraPage = () => (
	<Layout headerColor='yellow'>
      <SEO title="Extra Extra" />

      <h1>Extra Extra Page Coming Soon</h1>
  	</Layout>
)

export default ExtraExtraPage;